<!--
 * @Description: 添加/编辑用户信息弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 20:33:56
 * @LastEditTime: 2022-07-28 14:00:56
 * @LastEditors: LiangYiNing
-->
<template>
  <!-- 添加/编辑用户 start -->
  <el-dialog
    v-dialogDrag
    :title="type == 'add' ? '添加用户' : '编辑用户'"
    :visible="addUserVisible"
    width="950px"
    :close-on-click-modal="false"
    v-loading="editDialogLoading"
    :element-loading-background="loadingBackground"
    :before-close="closeDialog"
    custom-class="add-user-dialog"
  >
    <el-form
      label-width="110px"
      ref="addForm"
      class="detailForm"
      :model="addForm"
      :rules="addFormRules"
      :inline="true"
    >
      <el-form-item label="工号" prop="userCode">
        <el-input
          placeholder="工号"
          v-model="addForm.userCode"
          :disabled="type == 'edit'"
          @input="addForm.userCode = addForm.userCode.replace(/[^\w\/]/gi, '')"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名称" prop="userName">
        <el-input placeholder="用户名称" v-model="addForm.userName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input placeholder="手机号" v-model="addForm.phone"></el-input>
      </el-form-item>

      <el-form-item label="用户角色" prop="userRoleId">
        <e6-vr-select
          multiple
          v-model="addForm.userRoleId"
          :data="roleList"
          placeholder=""
          title="用户角色"
          clearable
          :props="{
            id: 'id',
            label: 'name'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="工程师属性" prop="engineerAttribute" v-if="showMore">
        <e6-vr-select
          v-model="addForm.engineerAttribute"
          :data="engineerAttributeData"
          placeholder="工程师属性"
          title="工程师属性"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="工程师技能认证" prop="engineerSkill" v-if="showMore">
        <e6-vr-select
          v-model="addForm.engineerSkill"
          :data="engineerSkillData"
          placeholder="工程师技能认证"
          title="工程师技能认证"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="所属部门" prop="userFrameworkId">
        <e6-select
          v-model="addForm.userFrameworkId"
          :dataList="treeData"
          :filterable="true"
          :slotTemplate="'tree'"
          :multiple="false"
          :issingleleaf="false"
          ref="t_select"
        ></e6-select>
      </el-form-item>
      <el-form-item label="职级" prop="userDutyId">
        <e6-vr-select
          v-model="addForm.userDutyId"
          :data="userDutyList"
          placeholder=""
          title="职级"
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="岗位" prop="userPostId">
        <e6-vr-select
          v-model="addForm.userPostId"
          :data="userPostList"
          :loading="userPostListLoading"
          placeholder=""
          title="岗位"
          clearable
          :props="{
            id: 'id',
            label: 'name'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="分机号" prop="phoneExt">
        <el-input placeholder="分机号" v-model="addForm.phoneExt"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      label-width="100px"
      :model="addForm"
      :inline="true"
      ref="ruleFormRef"
      v-if="showMore"
    >
      <div
        v-for="(item, index) in addForm.engineerFacilitatorList"
        :key="index"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item
              label="归属服务商"
              :rules="{
                required: true,
                message: '请选择归属服务商',
                trigger: ['blur', 'change']
              }"
              :prop="'engineerFacilitatorList.' + index + '.facilitatorId'"
            >
              <e6-vr-select
                v-model="item.facilitatorId"
                placeholder="归属服务商"
                title="归属服务商"
                clearable
                :data="facilitatorData"
                :props="{
                  id: 'serviceNo',
                  label: 'serviceProvider'
                }"
                style="width:200px;"
                @change="
                  (val, node) => {
                    changeFacilitator(val, node, index);
                  }
                "
              ></e6-vr-select>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item
              label="生效周期"
              :rules="{
                required: true,
                message: '请选择生效周期',
                validator: (rules, value, cb) => {
                  checkTime(rules, value, cb, item.time);
                },
                trigger: ['blur', 'change']
              }"
              :prop="'engineerFacilitatorList.' + index + '.time'"
            >
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="生效周期"
                ref="DatePicker"
                :speedy="false"
                v-model="item.time"
                title="生效周期"
                :picker-options="pickerOption"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="生效周期（始）"
                end-placeholder="生效周期（止）"
                style="width:300px;"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item label="" class="icon">
              <i
                class="e6-icon-add_line1"
                title="新增"
                @click="handleAdd"
                v-if="index == 0"
              ></i>
              <i
                class="e6-icon-delete_line1"
                title="删除"
                v-if="index > 0"
                @click="deleteColumn(index)"
              ></i>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import handleTree from "../mixins/index";
import {
  saveUser,
  updateBasic,
  // getUserRoleList,
  jobList,
  getBasic,
  findDownList,
  getFrameworkTree,
  getServiceProviderListPage
} from "@/api";
import { mobileValid } from "@/utils/validate";
import { printError } from "@/utils/util";
export default {
  name: "addUserDialog",
  components: {},
  data() {
    //自定义校验规则
    var checkTime = (rule, value, cb, time) => {
      let notEmpty = time.filter(ele => ele);
      if (notEmpty.length != 2) {
        cb(new Error("请选择生效周期"));
      } else {
        cb();
      }
    };
    return {
      checkTime,
      editDialogLoading: false,
      userPostListLoading: false,
      treeData: [], //所属部门
      userDutyList: [], //职级
      userPostList: [], //岗位下拉框
      addForm: {
        userCode: "", //工号
        userName: "", //用户名称
        phone: "", //手机号
        phoneExt: "", //分机号
        userRoleId: [], //用户角色
        userDutyId: "", //职级
        engineerAttribute: "",
        engineerSkill: "",
        userPostId: "", //岗位
        userFrameworkId: [], //所属部门
        engineerFacilitatorList: [
          {
            facilitatorId: "",
            isExamine: "",
            serviceStartTime: "",
            serviceEndTime: "",
            time: []
          }
        ]
      },

      engineerAttributeData: [],
      engineerSkillData: [],
      pickerOption: {
        disabledDate(time) {
          let timeStamp = moment(time).valueOf();
          let nowTime = moment()
            .startOf("day")
            .valueOf();
          return timeStamp < nowTime;
        }
      },
      addFormRules: {
        userCode: [
          {
            required: true,
            message: "请输入用户工号",
            trigger: ["blur", "change"]
          }
        ],
        userName: [
          {
            required: true,
            message: "请输入用户名称",
            trigger: ["blur", "change"]
          }
        ],
        phone: mobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        }),
        userRoleId: [
          {
            required: true,
            message: "请选择用户角色",
            trigger: ["blur", "change"]
          }
        ],
        userPostId: [
          {
            required: true,
            message: "请选择岗位",
            trigger: ["blur", "change"]
          }
        ],
        userDutyId: [
          {
            required: true,
            message: "请选择职级",
            trigger: ["blur", "change"]
          }
        ],
        userFrameworkId: [
          {
            required: true,
            message: "请选择所属部门",
            trigger: ["blur", "change"]
          }
        ],
        engineerAttribute: [
          {
            required: true,
            message: "请选择工程师属性",
            trigger: ["blur", "change"]
          }
        ],
        engineerSkill: [
          {
            required: true,
            message: "请选择工程师技能认证",
            trigger: ["blur", "change"]
          }
        ]
      },
      filterRoles: [],
      facilitatorData: []
    };
  },
  props: ["userId", "addUserVisible", "type", "roleList"],
  mixins: [base, handleTree],
  computed: {
    showMore() {
      let roleList = this.roleList;
      //过滤出选中的角色
      let filterRoles = roleList.filter(ele => {
        return this.addForm.userRoleId.indexOf(ele.id) > -1;
      });
      let val = filterRoles.some(item => item.roleGroup == 1);

      return val;
    }
  },
  created() {},
  watch: {
    addUserVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTreeData();
        }
      }
    },
    "addForm.userFrameworkId": {
      immediate: true,
      handler(val) {
        if (val && val.length) {
          this.getJob(val[0]);
        }
      }
    }
  },
  methods: {
    async initData() {
      let promiseList = [
        findDownList(["frameworkDuty", "engineerAttribute", "engineerSkill"]),
        getServiceProviderListPage({ page: 1, pageSize: 999999 })
      ];
      let [enumRes, facilitatorRes] = await Promise.all(promiseList);
      //职级下拉框
      this.userDutyList = this.getFreezeResponse(enumRes, {
        path: "data.frameworkDuty"
      });
      //服务商
      this.facilitatorData = this.getFreezeResponse(facilitatorRes, {
        path: "data.array"
      });
      //工程师属性
      this.engineerAttributeData = this.getFreezeResponse(enumRes, {
        path: "data.engineerAttribute"
      });
      //工程师技能
      this.engineerSkillData = this.getFreezeResponse(enumRes, {
        path: "data.engineerSkill"
      });
    },
    //获取部门树型结构
    async getTreeData() {
      try {
        this.editDialogLoading = true;
        let res = await getFrameworkTree();
        this.handleTreeData(
          res.data && res.data.children ? res.data.children : []
        );
        this.initData();
        if (this.type == "edit") {
          this.getBasicInfo();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取基本信息
    async getBasicInfo() {
      try {
        this.editDialogLoading = true;
        let res = await getBasic({ id: this.userId });
        //基本信息
        let addForm = _.cloneDeep(res.data);
        addForm.userDutyId = addForm.userDutyId.toString();
        addForm.engineerSkill = addForm.engineerSkill
          ? addForm.engineerSkill.toString()
          : "";
        addForm.engineerAttribute = addForm.engineerAttribute
          ? addForm.engineerAttribute.toString()
          : "";
        addForm.userFrameworkId = [addForm.userFrameworkId];
        addForm.userRoleId = addForm.userRoleId.map(item => {
          return item.toString();
        });
        addForm.engineerFacilitatorList =
          addForm.engineerFacilitatorList &&
          addForm.engineerFacilitatorList.length
            ? addForm.engineerFacilitatorList
            : [
                {
                  facilitatorId: "",
                  isExamine: "",
                  serviceStartTime: "",
                  serviceEndTime: "",
                  time: []
                }
              ];
        addForm.engineerFacilitatorList.map(item => {
          item.isExamine = item.isExamineValue ? item.isExamine.toString() : "";
          item.time = [
            item.serviceStartTimeTim ? item.serviceStartTimeTim : "",
            item.serviceEndTimeTim ? item.serviceEndTimeTim : ""
          ];
        });
        this.addForm = addForm;
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //获取部门下岗位
    async getJob(id) {
      try {
        this.userPostListLoading = true;
        let res = await jobList({ id });
        this.userPostList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.userPostListLoading = false;
      }
    },

    changeFacilitator(val, node, index) {
      let engineerFacilitatorList = this.addForm.engineerFacilitatorList;
      engineerFacilitatorList[index].isExamine = node.isExamine.toString();
    },

    //新增
    handleAdd() {
      let engineerFacilitatorList = this.addForm.engineerFacilitatorList;
      engineerFacilitatorList.push({
        facilitatorId: "",
        isExamine: "",
        serviceStartTime: "",
        serviceEndTime: "",
        time: []
      });
    },
    //删除单行
    deleteColumn(index) {
      let engineerFacilitatorList = _.cloneDeep(
        this.addForm.engineerFacilitatorList
      );
      engineerFacilitatorList.splice(index, 1);
      this.addForm.engineerFacilitatorList = engineerFacilitatorList;
    },
    //点击确定添加
    handleSubmit() {
      const p1 = new Promise(resolve => {
        this.$refs["addForm"].validate(valid => {
          if (valid) resolve();
        });
      });
      const p2 = new Promise(resolve => {
        this.$refs["ruleFormRef"].validate(valid => {
          if (valid) resolve();
        });
      });
      if (this.showMore) {
        Promise.all([p1, p2]).then(() => {
          this.handleRequestData();
        });
      } else {
        Promise.all([p1]).then(() => {
          this.handleRequestData();
        });
      }
    },
    handleRequestData() {
      let facilitatorList = _.cloneDeep(this.addForm.engineerFacilitatorList);
      facilitatorList.map(item => {
        item.serviceEndTime = item.time[1];
        item.serviceStartTime = item.time[0];
      });
      let engineerFacilitatorList = facilitatorList.filter(
        item =>
          item.facilitatorId && item.serviceStartTime && item.serviceEndTime
      );
      if (this.type == "add") {
        this.saveUser(engineerFacilitatorList);
      } else {
        this.updateBasic(engineerFacilitatorList);
      }
    },
    //添加用户请求
    async saveUser(engineerFacilitatorList) {
      try {
        this.editDialogLoading = true;
        delete this.addForm.privilegeId;
        let res = await saveUser({
          ...this.addForm,
          userFrameworkId: this.addForm.userFrameworkId.join(),
          engineerFacilitatorList: this.showMore ? engineerFacilitatorList : [],
          engineerAttribute: this.showMore
            ? this.addForm.engineerAttribute
            : "",
          engineerSkill: this.showMore ? this.addForm.engineerSkill : ""
        });
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //编辑用户信息请求
    async updateBasic(engineerFacilitatorList) {
      try {
        this.editDialogLoading = true;
        delete this.addForm.privilegeId;
        let res = await updateBasic({
          ...this.addForm,
          id: this.addForm.userId,
          userFrameworkId: this.addForm.userFrameworkId.join(),
          engineerFacilitatorList: this.showMore ? engineerFacilitatorList : [],
          engineerAttribute: this.showMore
            ? this.addForm.engineerAttribute
            : "",
          engineerSkill: this.showMore ? this.addForm.engineerSkill : ""
        });
        if (res.code == "OK") {
          this.$message.success("编辑成功");
          this.$emit("refreshData");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.editDialogLoading = false;
      }
    },
    //关闭弹框
    closeDialog() {
      this.addForm = {
        userCode: "", //工号
        userName: "", //用户名称
        phone: "", //手机号
        phoneExt: "", //分机号
        userRoleId: [], //用户角色
        userDutyId: "", //职级
        engineerAttribute: "",
        engineerSkill: "",
        userPostId: "", //岗位
        userFrameworkId: [], //所属部门
        engineerFacilitatorList: [
          {
            facilitatorId: "",
            isExamine: "",
            serviceStartTime: "",
            serviceEndTime: "",
            time: []
          }
        ]
      };
      this.$refs.addForm.resetFields();
      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-user-dialog {
  .el-dialog__body {
    padding: 20px;
    box-sizing: border-box;
    .detailForm {
      .el-input {
        width: 300px;
      }
      .base-select {
        width: 300px !important;
        .sub-selected-value {
          width: 300px !important;
        }
      }
    }
  }
}
.icon {
  i {
    margin-right: 10px;
    font-size: 20px;
    color: #46bfea;
  }
}
</style>
