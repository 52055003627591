var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.editDialogLoading),expression:"editDialogLoading"}],attrs:{"title":_vm.type == 'add' ? '添加用户' : '编辑用户',"visible":_vm.addUserVisible,"width":"950px","close-on-click-modal":false,"element-loading-background":_vm.loadingBackground,"before-close":_vm.closeDialog,"custom-class":"add-user-dialog"}},[_c('el-form',{ref:"addForm",staticClass:"detailForm",attrs:{"label-width":"110px","model":_vm.addForm,"rules":_vm.addFormRules,"inline":true}},[_c('el-form-item',{attrs:{"label":"工号","prop":"userCode"}},[_c('el-input',{attrs:{"placeholder":"工号","disabled":_vm.type == 'edit'},on:{"input":function($event){_vm.addForm.userCode = _vm.addForm.userCode.replace(/[^\w\/]/gi, '')}},model:{value:(_vm.addForm.userCode),callback:function ($$v) {_vm.$set(_vm.addForm, "userCode", $$v)},expression:"addForm.userCode"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"用户名称","prop":"userName"}},[_c('el-input',{attrs:{"placeholder":"用户名称"},model:{value:(_vm.addForm.userName),callback:function ($$v) {_vm.$set(_vm.addForm, "userName", $$v)},expression:"addForm.userName"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"手机号","prop":"phone"}},[_c('el-input',{attrs:{"placeholder":"手机号"},model:{value:(_vm.addForm.phone),callback:function ($$v) {_vm.$set(_vm.addForm, "phone", $$v)},expression:"addForm.phone"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"用户角色","prop":"userRoleId"}},[_c('e6-vr-select',{attrs:{"multiple":"","data":_vm.roleList,"placeholder":"","title":"用户角色","clearable":"","props":{
          id: 'id',
          label: 'name'
        }},model:{value:(_vm.addForm.userRoleId),callback:function ($$v) {_vm.$set(_vm.addForm, "userRoleId", $$v)},expression:"addForm.userRoleId"}})],1),_vm._v(" "),(_vm.showMore)?_c('el-form-item',{attrs:{"label":"工程师属性","prop":"engineerAttribute"}},[_c('e6-vr-select',{attrs:{"data":_vm.engineerAttributeData,"placeholder":"工程师属性","title":"工程师属性","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        }},model:{value:(_vm.addForm.engineerAttribute),callback:function ($$v) {_vm.$set(_vm.addForm, "engineerAttribute", $$v)},expression:"addForm.engineerAttribute"}})],1):_vm._e(),_vm._v(" "),(_vm.showMore)?_c('el-form-item',{attrs:{"label":"工程师技能认证","prop":"engineerSkill"}},[_c('e6-vr-select',{attrs:{"data":_vm.engineerSkillData,"placeholder":"工程师技能认证","title":"工程师技能认证","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        }},model:{value:(_vm.addForm.engineerSkill),callback:function ($$v) {_vm.$set(_vm.addForm, "engineerSkill", $$v)},expression:"addForm.engineerSkill"}})],1):_vm._e(),_vm._v(" "),_c('el-form-item',{attrs:{"label":"所属部门","prop":"userFrameworkId"}},[_c('e6-select',{ref:"t_select",attrs:{"dataList":_vm.treeData,"filterable":true,"slotTemplate":'tree',"multiple":false,"issingleleaf":false},model:{value:(_vm.addForm.userFrameworkId),callback:function ($$v) {_vm.$set(_vm.addForm, "userFrameworkId", $$v)},expression:"addForm.userFrameworkId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"职级","prop":"userDutyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.userDutyList,"placeholder":"","title":"职级","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        }},model:{value:(_vm.addForm.userDutyId),callback:function ($$v) {_vm.$set(_vm.addForm, "userDutyId", $$v)},expression:"addForm.userDutyId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"岗位","prop":"userPostId"}},[_c('e6-vr-select',{attrs:{"data":_vm.userPostList,"loading":_vm.userPostListLoading,"placeholder":"","title":"岗位","clearable":"","props":{
          id: 'id',
          label: 'name'
        }},model:{value:(_vm.addForm.userPostId),callback:function ($$v) {_vm.$set(_vm.addForm, "userPostId", $$v)},expression:"addForm.userPostId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"分机号","prop":"phoneExt"}},[_c('el-input',{attrs:{"placeholder":"分机号"},model:{value:(_vm.addForm.phoneExt),callback:function ($$v) {_vm.$set(_vm.addForm, "phoneExt", $$v)},expression:"addForm.phoneExt"}})],1)],1),_vm._v(" "),(_vm.showMore)?_c('el-form',{ref:"ruleFormRef",attrs:{"label-width":"100px","model":_vm.addForm,"inline":true}},_vm._l((_vm.addForm.engineerFacilitatorList),function(item,index){return _c('div',{key:index},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('el-form-item',{attrs:{"label":"归属服务商","rules":{
              required: true,
              message: '请选择归属服务商',
              trigger: ['blur', 'change']
            },"prop":'engineerFacilitatorList.' + index + '.facilitatorId'}},[_c('e6-vr-select',{staticStyle:{"width":"200px"},attrs:{"placeholder":"归属服务商","title":"归属服务商","clearable":"","data":_vm.facilitatorData,"props":{
                id: 'serviceNo',
                label: 'serviceProvider'
              }},on:{"change":function (val, node) {
                  _vm.changeFacilitator(val, node, index);
                }},model:{value:(item.facilitatorId),callback:function ($$v) {_vm.$set(item, "facilitatorId", $$v)},expression:"item.facilitatorId"}})],1)],1),_vm._v(" "),_c('el-col',{attrs:{"span":14}},[_c('el-form-item',{attrs:{"label":"生效周期","rules":{
              required: true,
              message: '请选择生效周期',
              validator: function (rules, value, cb) {
                _vm.checkTime(rules, value, cb, item.time);
              },
              trigger: ['blur', 'change']
            },"prop":'engineerFacilitatorList.' + index + '.time'}},[_c('e6-date-picker',{ref:"DatePicker",refInFor:true,staticStyle:{"width":"300px"},attrs:{"popper-class":"no-atTheMoment","label":"生效周期","speedy":false,"title":"生效周期","picker-options":_vm.pickerOption,"default-time":['00:00:00', '23:59:59'],"start-placeholder":"生效周期（始）","end-placeholder":"生效周期（止）"},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"icon",attrs:{"label":""}},[(index == 0)?_c('i',{staticClass:"e6-icon-add_line1",attrs:{"title":"新增"},on:{"click":_vm.handleAdd}}):_vm._e(),_vm._v(" "),(index > 0)?_c('i',{staticClass:"e6-icon-delete_line1",attrs:{"title":"删除"},on:{"click":function($event){return _vm.deleteColumn(index)}}}):_vm._e()])],1)],1)],1)}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }